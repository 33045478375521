<template>
  <div id="careers-view">
    <content-section
      type="main"
      asset="17494b064bf99f6c990fc1801e84943e-left.jpg"
      :title="$localize.get('careers_page_main_title')"
      :subtitle="$localize.get('careers_page_main_subtitle')"
    >
      <template v-slot:buttons>
        <h-button
          class="button--join"
          @click.native="$store.dispatch('OPEN_LOGIN_PAGE')"
          large filled green
        >{{$localize.get('apply_for_job')}}
        </h-button>
      </template>
    </content-section>

    <content-section
      type="workflow-section"
      :title="$localize.get('workflow')"
      :tabs="workflowSectionTabs"
      blue-bg
    />

    <content-section
      type="simple"
      :title="$localize.get('equal_opportunities')"
      :subtitle="$localize.get('equal_opportunities_description')"
      title-color="blue"
    >
      <template v-slot:content>
        <div class="section--equal-opportunities__content">
          <div class="section--equal-opportunities__content__1">
            <div class="section--equal-opportunities__content__1__text-content">
              <div v-html="$localize.get('equal_opportunities_description')"></div>
            </div>
            <div class="section--equal-opportunities__content__1__media">
              <img src="../assets/dd44084a771a999af9f09f97e6c592ce.jpg">
              <div class="section--equal-opportunities__content__1__media__floating-block"></div>
            </div>
          </div>
          <div class="section--equal-opportunities__content__2">
            <div class="section--equal-opportunities__content__2__cards">
              <div class="section--equal-opportunities__content__2__cards__item">
                <img src="../assets/icon-globe-outline.svg">
                <div class="section--equal-opportunities__content__2__cards__item__text">
                  {{$localize.get('equal_opportunities_1')}}
                </div>
              </div>
              <div class="section--equal-opportunities__content__2__cards__item">
                <img src="../assets/icon-house.svg">
                <div class="section--equal-opportunities__content__2__cards__item__text">
                  {{$localize.get('equal_opportunities_2')}}
                </div>
              </div>
              <div class="section--equal-opportunities__content__2__cards__item">
                <img src="../assets/icon-growth.svg">
                <div class="section--equal-opportunities__content__2__cards__item__text">
                  {{$localize.get('equal_opportunities_3')}}
                </div>
              </div>
              <div class="section--equal-opportunities__content__2__cards__item">
                <img src="../assets/icon-money-bag.svg">
                <div class="section--equal-opportunities__content__2__cards__item__text">
                  {{$localize.get('equal_opportunities_4')}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:buttons>
        <h-button
          class="button--join"
          large filled green
        >{{$localize.get('apply_for_job')}}
        </h-button>
      </template>
    </content-section>
    
    <content-section
      type="simple"
      asset="73c7670e185944e0f7ee2e109ea43c27.jpg"
      :title="$localize.get('why_work_with_us')"
      blue-bg
    >
      <template v-slot:content>
        <div class="section--why-work-with-us__content">
          <div class="section--why-work-with-us__content__text-content">
            <div class="section--why-work-with-us__content__text-content__description-item">
              <img src="../assets/icon-num-1.svg">
              <div v-html="$localize.get('why_work_with_us_1')"></div>
            </div>
            <div class="section--why-work-with-us__content__text-content__description-item">
              <img src="../assets/icon-num-2.svg">
              <div v-html="$localize.get('why_work_with_us_2')"></div>
            </div>
            <div class="section--why-work-with-us__content__text-content__description-item">
              <img src="../assets/icon-num-3.svg">
              <div v-html="$localize.get('why_work_with_us_3')"></div>
            </div>
            <div class="section--why-work-with-us__content__text-content__description-item">
              <img src="../assets/icon-num-4.svg">
              <div v-html="$localize.get('why_work_with_us_4')"></div>
            </div>
          </div>
          <div class="section--why-work-with-us__content__media">
            <img src="../assets/73c7670e185944e0f7ee2e109ea43c27.jpg">
          </div>
        </div>
      </template>
      <template v-slot:buttons>
        <h-button
          class="button--join"
          @click.native="$store.dispatch('OPEN_LOGIN_PAGE')"
          large outlined green
        >{{$localize.get('apply_for_job')}}
        </h-button>
      </template>
    </content-section>
    
    <content-section
      type="simple"
      :title="$localize.get('testimonials')"
      center-buttons
    >
      <template v-slot:content>
        <media-slider
          :items="testimonials"
        ></media-slider>
      </template>
      <template v-slot:buttons>
        <h-button
          class="button--join"
          large filled green
        >{{$localize.get('apply_for_job')}}
        </h-button>
      </template>
    </content-section>
  </div>
</template>

<script>
export default {
  name: 'Careers',
  computed: {
    testimonials () {return this.$store.state.testimonials},
    workflowSectionTabs () {
      return {
        headers: [],
        content: [
          [
            {
              title: this.$localize.get('choose_your_project'),
              subtitle: this.$localize.get('choose_your_project_subtitle')
            },
            {
              title: this.$localize.get('project_interview'),
              subtitle: this.$localize.get('project_interview_subtitle')
            },
            {
              title: this.$localize.get('work_on_project'),
              subtitle: this.$localize.get('work_on_project_subtitle_3')
            },
            {
              title: this.$localize.get('completion_of_the_project'),
              subtitle: this.$localize.get('completion_of_the_project_subtitle_3')
            },
          ]
        ]
      }
    },
  }
}
</script>

<style lang="scss">
.section {
  &--why-work-with-us {
    &__content {
      display: flex;
      align-items: center;
      justify-content: center;
      &__text-content {
        margin-right: 64px;
        font-size: 18px;
        @media (max-width: 375px) {
          font-size: 16px;
        }
        &__description-item {
          display: flex;
          align-items: center;
          margin-bottom: 48px;
          img {
            margin-right: 16px;
          }
        }
      }
      &__media {
        img {
          box-shadow: var(--shadow-1);
        }
        @media (max-width: $md) {
          display: none;
        }
      }
    }
  }
  &--equal-opportunities {
    &__content {
      display: grid;
      justify-content: center;
      gap: 122px;
      grid-template-columns: 1fr 1fr;
      max-width: var(--max-content-width);
      @media (max-width: $md) {
        display: flex;
        flex-direction: column;
        margin: 0 auto;
        gap: unset;
      }
      @media (max-width: $xs) {
        width: var(--main-view-section-width-xs);
      }
      &__1 {
        @media (max-width: $sm) {
          display: flex;
          flex-direction: column-reverse;
          margin-bottom: 64px;
        }
        &__text-content {
          max-width: 488px;
          font-size: 18px;
          line-height: 161%;
          letter-spacing: 0.04em;
          @media (max-width: $md) {
            max-width: 100%;
          }
        }
        &__media {
          margin: 36px 0;
          position: relative;
          max-width: 488px;
          height: 385px;
          @media (max-width: $md) {
            max-width: 100%;
          }
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
          &__floating-block {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: var(--blue-main);
            transform: translateX(-144px);
            z-index: -1;
          }
        }
      }
      &__2 {
        &__cards {
          display: grid;
          align-items: center;
          justify-content: center;
          grid-template-columns: 1fr 1fr;
          gap: 24px;
          @media (max-width: 375px) {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
          }
          &__item {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            width: 272px;
            height: 272px;
            margin: 0 auto;
            border-radius: 50%;
            box-shadow: var(--shadow-1);
            &:hover {
              color: #fff;
              background-color: var(--blue-main);
              transition: background-color 0.2s;
              img {
                filter: brightness(100)
              }
            }
            @media (max-width: $sm) {
              width: 154px;
              height: 154px;
            }
            img {
              @media (max-width: $sm) {
                width: 42px;
                height: 42px;
              }
            }
            &__text {
              padding: 16px 48px;
              text-align: center;
              @media (max-width: $sm) {
                padding: 6px 20px;
                font-size: 14px;
              }
            }
          }
        }
      }
    } 
  }
}
</style>